(function($){
    //Validacao de email e Mascara de telefone
    jQuery.validator.addMethod("testEmail", function (value, element) {
        return this.optional(element) || /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/i.test(value);
    }, "Digite e-mail valido.");

    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    }, spOptions = {
        onKeyPress: function (val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };
    $('.celular-input').mask(SPMaskBehavior, spOptions);
    var CPFMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '000.000.000-00' : '000.000.000-00';
    }, cpfOptions = {
        onKeyPress: function (val, e, field, options) {
            field.mask(CPFMaskBehavior.apply({}, arguments), options);
        }
    }
    $('.cpf-input').mask(CPFMaskBehavior, cpfOptions);
    var DateMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 8 ? '00-00-0000' : '00-00-0000';
    }, DateOptions= {
        onKeyPress: function (val, e, field, options) {
            field.mask(DateMaskBehavior.apply({}, arguments), options);
        }
    }
    $('.date-input').mask(DateMaskBehavior, DateOptions);
    var AppForm = function(){
        return{
            searchForm: function () {
                var cont = 0;
                $('#formConsulta').validate({
                    rules: {
                        chassi: {
                            required: true,
                            minlength: 7
                        },
                        optionCliente: {
                            required: true
                        }
                    },
                    messages: {
                        chassi: {
                            required: 'Informe o número do chassi',
                            minlength: 'Informe o número completo do chassi'
                        },
                        optionCliente: {
                            required: 'Selecione o tipo de Cliente'
                        }
                    }, submitHandler: function (form) {
                        data = $('#formConsulta').serialize();
                        var actBtn = $(form).find('button[type="submit"]');
                        var oldText = actBtn.text();
                        var url = '/chassi';
                        var divTarget = $('.content-sect');
                        oldText = actBtn.text();
                        actBtn.text('Consultando ...');
                        actBtn.prop('disabled', true);
                        $.ajax({
                            url: url,
                            data: data,
                            dataType: 'json',
                            method: 'post'
                        }).done(function(jsondata){
                            console.info(jsondata);
                            if(jsondata.length == 0){
                                if($('label.main.error').length == 0){
                                    element = '<label class="main error">Não consta em nossa base de dados nenhuma Campanha de Recall pendente para o chassi informado.</label>';
                                    actBtn.text(oldText);
                                    actBtn.prop('disabled', false);
                                    divTarget.removeClass('loaded');
                                    $('.newsletter-sect').removeClass('loaded');
                                    $('.main-sect .content-wrapper').append(element);
                                }
                                else {
                                    divTarget.removeClass('loaded');
                                    $('.newsletter-sect').removeClass('loaded');
                                    actBtn.text(oldText);
                                    actBtn.prop('disabled', false);
                                    return false;
                                }
                            }else{
                                $('label.error').remove();
                                divTarget.find('.block').remove();
                                divTarget.find('.car-info .chassi .value').html(jsondata[0].num_chassi);
                                divTarget.find('.car-info .modelo .value').html(jsondata[0].modelo);
                                $.each(jsondata, function (data) {
                                    element =
                                        '<div class="block clearfix">' +
                                        '<div class="col name"> ' +
                                        '<div class="title"> Nome da Campanha </div> ' +
                                        '<div class="value">' + jsondata[data].descricao_campanha+ '</div>' +
                                        '</div>'+
                                        '<div class="col status"> ' +
                                        '<div class="title"> Status </div> ' +
                                        '<div class="value">' + jsondata[data].status+ '</div>' +
                                        '</div>'+
                                        '<div class="col obs"> ' +
                                        '<div class="title"> Observações </div> ' +
                                        '<div class="value">' + jsondata[data].observacao+ '</div>' +
                                        '</div>'+
                                        '</div>';
                                    divTarget.find('.block-wrapper').append(element);
                                })
                                setTimeout(function(){
                                    divTarget.addClass('loaded');
                                    $('.newsletter-sect').addClass('loaded');
                                    actBtn.text(oldText);
                                    actBtn.prop('disabled', false)
                                }, 1000);
                                if(divTarget.find('.block').length > 1){
                                    divTarget.find('.block').last().addClass('last');
                                }
                            }
                        });
                        value = $('#formConsulta input[name="chassi"]').val();
                        target = $('input#chassiNews');
                        target.val(value);
                        return false;
                    }
                })
            },
            newsForm: function () {
                $('#formNewsletter').validate({
                   rules: {
                       nome: {
                           required: true,
                           minlength: 2,
                       },
                       email: {
                           required: true,
                           testEmail: true,
                           email: true
                       },
                       cpf: {
                           required: true,
                           minlength: 11
                       }
                   },
                    messages:{
                       nome: {
                           required: 'Informe seu nome',
                           minlength: 'Informe seu nome'
                       },
                        email: {
                           required: 'Informe seu e-mail',
                            email: 'Informe um e-mail válido'
                        },
                        cpf:{
                            required: 'Informe seu CPF',
                            minlength: 'Informe seu CPF'
                        }
                    }, submitHandler: function(form){
                        var actBtn = $(form).find('button[type="submit"]');
                        var oldText = actBtn.text();
                        actBtn.text('Enviando...');
                        actBtn.prop('disabled', true);
                        var data = $(form).serialize();
                        $.ajax({
                            data: data,
                            url: '/cliente',
                            method: 'post'
                        }).done(function (data) {
                            console.warn('Dados do form: ', data);
                            element = '<label class="success">Cadastrado com sucesso!</label>';
                            $(form).find('.info').append(element);
                            $.each($(form).find('input'), function () {
                                $(this).val('');
                                $(this).removeClass('valid');
                            })
                            setTimeout(function () {
                                $('.info label.success').hide();
                            }, 5000);
                            actBtn.text(oldText);
                            actBtn.prop('disabled', false);
                        }).error(function () {
                            actBtn.text(oldText);
                            actBtn.prop('disabled', false);
                        })

                    }
                });
            },
            init: function () {
                AppForm.searchForm();
                AppForm.newsForm();
            }
        }
    }();

    //Doc Ready
    $(function(){
        AppForm.init();
    });
})(jQuery);